import axios from "axios";
import authHeader from "./auth-header";
import { DateTime } from "luxon";

const API_URL = process.env.VUE_APP_API_URL;

class EventService {
  // query('client_id').isNumeric(),
  // query('site').default(-1).isNumeric(),
  // query('registration').default('').isString(),
  // query('camera').default(-1).isNumeric(),
  // query('direction').default('').isString(),
  // query('status').default('').isString(),
  // query('start_date').default('').isString(),
  // query('end_date').default('').isString(),
  // query('start_time').default('').isString(),
  // query('end_time').default('').isString(),
  getEvents(
    page,
    client,
    {
      registration = null,
      site = null,
      camera = null,
      direction = null,
      start_date = null,
      start_time = null,
      end_date = null,
      end_time = null,
      status = null,
    }
  ) {
    const params = new URLSearchParams({
      page: page || 1,
      client_id: client,
    });
    if (registration !== null)
      params.append("registration", registration.replace(/\s/g, ""));
    if (site !== null) params.append("site", site);
    if (camera !== null) params.append("camera", camera);
    if (direction !== null) params.append("direction", direction);
    if (start_date !== null) {
      start_date = DateTime.fromFormat(start_date, "yyyy-MM-dd").toFormat(
        "dd/MM/yyyy"
      );
      console.log(start_date);
      params.append("start_date", start_date);
    }
    if (end_date !== null) {
      end_date = DateTime.fromFormat(end_date, "yyyy-MM-dd").toFormat(
        "dd/MM/yyyy"
      );
      console.log(end_date);
      params.append("end_date", end_date);
    }
    if (start_time !== null) params.append("start_time", start_time + ":00");
    if (end_time !== null) params.append("end_time", end_time + ":00");
    if (status !== null) params.append("status", status);
    params.append("count", 24);

    return axios.get(
      `https://api.varsanpr.com/api/event?` + params.toString(),
      { headers: authHeader() }
    );
  }

  getEvent(client, event) {
    return axios.get(
      `https://api.varsanpr.com/api/event/${event}?client_id=${client}`,
      { headers: authHeader() }
    );
  }

  getEventImage(filename, site_id) {
    axios
      .get(
        `https://api.varsanpr.com/api/event/thumbnail/${filename}?site_id=${site_id}`,
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        return `data:image/jpeg;base64,${Buffer.from(
          response.data,
          "binary"
        ).toString("base64")}`;
      });
  }

  updateCustomerServiceMessage(client, event_id, message) {
    return axios.patch(
      `${process.env.VUE_APP_API_URL}event/${event_id}/csm`,
      {
        message: message,
        client_id: client,
      },
      { headers: authHeader() }
    );
  }

  blacklist(site_id, event_id, reason) {
    return axios.put(
      `https://api.varsanpr.com/api/event/blacklist/${event_id}`,
      {
        site_id: site_id,
        reason: reason,
      },
      { headers: authHeader() }
    );
  }

  unblacklist(site_id, event_id) {
    return axios.delete(
      `https://api.varsanpr.com/api/event/blacklist/${event_id}`,
      {
        data: {
          site_id: site_id,
        },
        headers: authHeader(),
      }
    );
  }
}

export default new EventService();
