<template>
  <!-- <div class="row flex">
    <div class="col-md-8 col-sm-12 flex">
      <div class="card" v-if="event !== null">
        <event-image v-if="event.image !== null" class="card-img-top" :filename="event.image" :site_id="event.site.id" :thumb="false" id="event_image"/>
        <div class="card-body">
            <h5 class="card-title">
              <span class="vrm bold number-plate-gbr btn m-r-xxs">{{event.vehicle.plate}}</span> {{ event.vehicle.context.make }} {{ event.vehicle.context.model }} {{ event.vehicle.context.colour }}
              <button class="btn btn-danger float-end" v-if="event.blacklist.local.reason === null" @click="blacklist">
                <div v-if="updatingBlacklist" class="spinner-border" role="status">
                    <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                </div>
                {{ $t('global.blacklist') }}
              </button>
              <button class="btn btn-primary float-end" @click="unblacklist" v-else>
                <div v-if="updatingBlacklist" class="spinner-border" role="status">
                    <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                </div>
                {{ $t('global.unblacklist') }}
              </button>
            </h5>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-6 flex" v-if="event !== null">
      <vehicle-notes v-if="event.vehicle !== null" :vehicle="event.vehicle"/>
    </div>
  </div>
  <div class="row" v-if="event !== null">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ $t('event.blacklist_entries') }}</h5>
          <table class="table">
            <thead>
                <tr>
                    <th scope="col">{{ $t('event.company') }}</th>
                    <th scope="col">{{ $t('event.reason_for_blacklist') }}</th>
                    <th scope="col">{{ $t('event.date_blacklisted') }}</th>
                    <th scope="col" v-if="event.blacklist.local.reason !== null">{{ $t('event.claims_outstanding') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="event.blacklist.local.reason !== null">
                    <th scope="row">{{ selectedClient.name }}</th>
                    <td>{{ $t('event.' + event.blacklist.local.reason.split(' ').join('_').toLowerCase()) }}</td>
                    <td><timestamp :time="event.blacklist.local.blacklisted_at" format="dd/MM/yyyy" /></td>
                    <td>£{{ event.blacklist.local.value }}</td>
                </tr>
                <tr v-for="(blacklist, index) in event.blacklist.national.by" :key="'blacklist-' + index">
                  <th scope="row">{{ blacklist.name }} (National Blacklist)</th>
                  <td>{{ $t('event.' + blacklist.reason.split(' ').join('_').toLowerCase()) }}</td>
                  <td><timestamp :time="blacklist.blacklisted_at" format="dd/MM/yyyy" /></td>
                  <td v-if="event.blacklist.local.reason !== null">N/A</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ $t('event.customer_service_message') }}</h5>
          <div class="alert alert-custom alert-indicator-left indicator-warning" role="alert">
              <div class="alert-content">
                  <span class="alert-title">{{ $t('event.important_notice') }}</span>
                  <span class="alert-text">{{ $t('event.notice') }}</span>
              </div>
          </div>
          <textarea class="form-control" v-model="csm" rows="5" placeholder="Enter your customer service message here..."></textarea>
          <button class="btn btn-success float-end" @click="updateCsm">
          <div v-if="updatingCsm" class="spinner-border" role="status">
              <span class="visually-hidden">{{ $t('global.loading') }}...</span>
          </div>
          Update
          </button>
        </div>
      </div>
    </div>
  </div> -->
  <div v-if="event !== null" class="row flex">

      <!-- Event Image
      <div class="col-md-8 col-sm-12 flex">
        <div class="card eventImage-container" v-if="event !== null">
          <EventImage 
            v-if="event.image !== null" 
            class="card-img-top" 
            :filename="event.image" 
            :site_id="event.site.id" 
            :thumb="false" 
            id="event_image"
            @load="magnify('event_image', 3)"
          />

          <div class="card-body image-footer m-t-xxs" :class="event.status !== 'blacklisted' ? 'bg-primary' : 'bg-danger'">
            Claim infromation
            <div v-if="event.status !== 'blacklisted'">
              <p> {{ event.blacklist.local.claims + ' ' + $t('event.claims_outstanding').toUpperCase()}}</p>
            </div>
            <div v-else>
              <p> 
                BLACKLISTED BY  <span class="fn-weight-500"> {{ event.site.name }} </span> 
                <span v-show="event.blacklist.local.reason !== null"> {{ `FOR ${event.blacklist.local.reason}`}} </span>
              </p>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-xl-8">
          <div v-if="event !== null">
            <EventImage 
              v-if="event.image !== null" 
              class="card-img-top" 
              :filename="event.image" 
              :site_id="event.site.id" 
              :thumb="false" 
              id="event_image"
            />
          </div>

          <div class="m-t-xxs m-b-md p-v-sm p-h-sm image-footer" :class="event.status === 'blacklisted' ? 'bg-danger' : 'bg-primary'">
            <div v-if="event.status !== 'blacklisted'">
              <p> {{ event.blacklist.local.claims + ' ' + $t('event.claims_outstanding').toUpperCase()}}</p>
            </div>
            <div v-else>
              <p> 
                BLACKLISTED BY  <span class="fn-weight-500"> {{ event.site.name }} </span> 
                <span v-if="event.blacklist.local.reason !== null"> 
                  <span v-if="event.blacklist.local.reason === 'Aggressive'"> FOR <strong> {{$t('blacklist.aggression')}} </strong> </span>
                  <span v-else> FOR <strong> {{event.blacklist.local.reason}} </strong> </span>
                </span>
              </p>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <span class="vrm bold number-plate-gbr btn m-r-xxs" style="width:fit-content; padding: 6px 7.5px;">{{event.vehicle.plate}}</span>
              <!-- Add to blacklist btn -->
              <button class="btn btn-danger float-end bg-danger" v-if="event.status !== 'blacklisted'" @click="blacklist" style="width:fit-content;">
                <div v-if="updatingBlacklist" class="spinner-border" role="status" style="margin-right: 5px">
                    <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                </div>
                <i :class="updatingBlacklist ? 'display-none' : '' " class="material-icons-outlined"> add </i>
                {{$t('blacklist.add_to_blacklist')}}
              </button>

              <!-- Remove form blacklist btn -->
              <button class="btn btn-danger float-end bg-primary" v-if="event.status === 'blacklisted'" @click="unblacklist" style="width:fit-content;">
                <div v-if="updatingBlacklist" class="spinner-border" role="status" style="margin-right: 5px">
                    <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                </div>
                <i :class="updatingBlacklist ? 'display-none' : '' " class="material-icons-outlined"> close </i>
                {{$t('blacklist.remove_from_blacklist')}}
              </button>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
            <div class="card alert alert-custom alert-indicator-top indicator-warning">
              <div class="card-title fn-weight-500"> {{$t('event.vehicle_information')}} </div>
              <div v-if="loading" class="parent-div">
                <div class="spinner-border center-div" role="status" style="margin-right: 5px">
                    <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                </div>
              </div>
              <div v-else class="card-body grid">
                <div class="text-muted"> {{$t('global.make')}} </div>
                <div class=""> {{vehicle.context.make}} </div>
                <div class="text-muted"> {{$t('global.model')}} </div>
                <div class=""> {{vehicle.context.model}} </div>
                <div class="text-muted"> {{$t('global.colour')}} </div>
                <div> {{vehicle.context.colour}} </div>
              </div>
            </div>

            <div class="card alert alert-custom alert-indicator-top indicator-info">
              <div class="card-title fn-weight-500"> {{$t('event.claim_information')}} </div>
              <div v-if="loading" class="parent-div">
                <div class="spinner-border center-div" role="status" style="margin-right: 5px">
                    <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                </div>
              </div>
              <div v-else class="card-body grid">
                <div class="text-muted"> {{$t('event.claims_outstanding')}} </div>
                <div> {{event.blacklist.local.claims}} </div>
                <div class="text-muted"> {{$t('event.reason_for_watchlist')}} </div>
                <div v-if="event.blacklist.local.reason === null"> {{$t('event.n_a')}} </div>
                <div v-else> {{event.blacklist.local.reason}} </div>
                <div class="text-muted"> {{$t('event.money_owed')}} </div>
                <div> {{event.blacklist.local.value}} </div>
              </div>
            </div>
          </div>
      </div>


      
      <!-- <div class="col-md-8">
        <div v-if="event !== null">
          <EventImage 
            v-if="event.image !== null" 
            class="card-img-top" 
            :filename="event.image" 
            :site_id="event.site.id" 
            :thumb="false" 
            id="event_image"
          />
        </div>
        <div class="m-t-xxs m-b-md p-v-sm p-h-sm image-footer" :class="event.status === 'blacklisted' ? 'bg-danger' : 'bg-primary'">
          <div v-if="event.status !== 'blacklisted'">
            <p> {{ event.blacklist.local.claims + ' ' + $t('event.claims_outstanding').toUpperCase()}}</p>
          </div>
          <div v-else>
            <p> 
              BLACKLISTED BY  <span class="fn-weight-500"> {{ event.site.name }} </span> 
              <span v-if="event.blacklist.local.reason !== null"> 
                <span v-if="event.blacklist.local.reason === 'Aggressive'"> FOR <strong> {{$t('blacklist.aggression')}} </strong> </span>
                <span v-else> FOR <strong> {{event.blacklist.local.reason}} </strong> </span>
              </span>
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="vrm bold number-plate-gbr btn m-r-xxs" style="width:fit-content; padding: 6px 7.5px;">{{event.vehicle.plate}}</span>
            <button class="btn btn-danger float-end bg-danger" v-if="event.status !== 'blacklisted'" @click="blacklist" style="width:fit-content;">
              <div v-if="updatingBlacklist" class="spinner-border" role="status" style="margin-right: 5px">
                  <span class="visually-hidden">{{ $t('global.loading') }}...</span>
              </div>
              <i :class="updatingBlacklist ? 'display-none' : '' " class="material-icons-outlined"> add </i>
              {{$t('blacklist.add_to_blacklist')}}
            </button>

            <button class="btn btn-danger float-end bg-primary" v-if="event.status === 'blacklisted'" @click="unblacklist" style="width:fit-content;">
              <div v-if="updatingBlacklist" class="spinner-border" role="status" style="margin-right: 5px">
                  <span class="visually-hidden">{{ $t('global.loading') }}...</span>
              </div>
              <i :class="updatingBlacklist ? 'display-none' : '' " class="material-icons-outlined"> close </i>
              {{$t('blacklist.remove_from_blacklist')}}
            </button>
          </div>
        </div>

      </div>

      <div class="flex flex-col col-md-4" style="flex-wrap: nowrap;">
        <div class="card" style="height: 50%">
          <div class="card-body">
            <h1 class="card-title fn-weight-500"> {{$t('event.vehicle_information')}} </h1>
            <div class="flex" style="margin-top:20px;">
              <div class="left col-md-6">
                <span> {{$t('global.make')}} </span>
                <span> {{$t('global.model')}} </span>
                <span> {{$t('global.colour')}} </span>
              </div>
              <div class="right col-md-6">
                <span> {{vehicle.context.make}} </span>
                <span> {{vehicle.context.model}} </span>
                <span> {{vehicle.context.colour}} </span>
              </div>
            </div>

          </div>
        </div>

        <div class="card" style="height: 50%">
          <div class="card-body">
            <h1 class="card-title fn-weight-500"> {{$t('event.claim_information')}} </h1>
            <div class="flex">
              <div class="left col-md-6">
                <span> {{$t('event.claims_outstanding')}} </span>
                <span> {{$t('event.reason_for_watchlist')}} </span>
                <span> {{$t('event.money_owed')}} </span>
              </div>
              <div class="right col-md-6">
                <span> {{event.blacklist.local.claims}} </span>
                <span v-if="event.blacklist.local.reason === null"> {{$t('event.n_a')}} </span>
                <span v-else> {{event.blacklist.local.reason}} </span>
                <span> {{event.blacklist.local.value}} </span>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row">
        <!-- Notes -->
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title fn-weight-500"> {{ $t('event.notes') }} </h5>
                    <div v-if="loading" class="parent-div">
                        <div class="spinner-border center-div" role="status" style="margin-right: 5px">
                            <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                        </div>
                    </div>
                    <div v-if="!loading" id="card-content" style="min-height: 331px;">
                        <p v-if="vehicle.notes.length < 1" class="no-notes"> {{$t('event.no_notes')}} </p>
                        <div v-else v-for="note in vehicle.notes" :key="note.index" class="msg-wrapper m-b-xs">
                            <span> {{ note.note }} </span>
                            <div class="note-timestamp m-t-xxs"> <Timestamp :time="note.created_at"/> <span> by {{note.user.email}} </span> </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <input type="text" v-model="newNote" placeholder="Write your message here ..." id="newNote" @keydown.enter="addNewNote"/> 
                    <i v-if="!addingNewNote" class="material-icons send-icon" title="Press Enter to send" @click="addNewNote"> send </i>
                    <div v-else class="spinner-border" role="status" style="margin-right: 5px">
                        <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Customer Service Message -->
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                   <h5 class="card-title fn-weight-500"> {{ $t('event.customer_service_message') }} </h5>
                    <MessageCard 
                        alertType="indicator-info"
                        :alertTitle="$t('claims.new.comments')" 
                        :alertText="$t('event.csm_info')"
                    />
                    <MessageCard 
                        alertType="indicator-danger"
                        :alertTitle="$t('claims.new.very_important')" 
                        :alertText="$t('event.csm_danger')"
                    /> 
                    <p v-if="vehicle.notes.length < 1" class="no-notes"> {{$t('event.no_csm')}} </p>
                    <div v-else class="msg-wrapper m-b-xs">
                        <!-- <span v-show="vehicle.customerServiceMessage.message === ''"> {{$t('event.no_csm')}} </span> -->
                        <span> {{ vehicle.customerServiceMessage.message.message }} </span>
                        <div class="note-timestamp m-t-xxs"> {{csm_timestamp}} </div>
                    </div> 
                </div>
                <div class="card-footer">
                    <input type="text" v-model="newCSM" placeholder="Write your message here ..." id="newNote" @keydown.enter="updateCustomerServiceMessage"/> 
                    <i v-if="!updatingCSM" class="material-icons send-icon" title="Press Enter to send" @click="updateCustomerServiceMessage"> send </i>
                    <div v-else class="spinner-border" role="status" style="margin-right: 5px">
                        <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                    </div>
                </div>
            </div>
        </div>
      </div>




      <!-- Notes -->
      <div class="col-xl-6">
        <div class="card m-t-sm" style="min-height: 450px;">
          <div v-show="!loading" class="card-body">
            <h5 class="card-title fn-weight-500"> {{ $t('event.notes') }} </h5>
            <div v-if="vehicle !== null" id="card-content">
              <p v-if="vehicle.notes.length < 1" class="no-notes"> {{$t('event.no_notes')}} </p>
              <div v-for="elem in vehicle.notes" :key="elem.index" class="msg-wrapper m-b-xs">
                <span> {{ elem.note }} </span>
                <div class="note-timestamp m-t-xxs">
                  <Timestamp :time="elem.created_at"/> <span> by {{elem.user.email}} </span>
                </div>
              </div>
            </div>
          </div>
          <div class="notes-footer flex-center">
            <div class="flex-center" style="width:100%; justify-content: space-between;">
              <input type="text" v-model="newNote" placeholder="Write your message here ..." class="col-md-9" id="newNote" @keydown.enter="createNote">
              <div class="col-md-3 flex-center"> 
                <button v-if="!addingNewNote" class="event-btn bg-primary flex-center" @click="createNote"> 
                  <div v-if="addingNewNote" class="spinner-border" role="status" style="margin-right: 5px">
                      <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                  </div>
                  <i class="material-icons" :class="addingNewNote ? 'display-none' : '' "> send </i> 
                  <span class="m-l-xxs"> {{$t('event.add_note')}} </span> 
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Customer Service Message -->
      <div class="col-xl-6">
        <div class="card m-t-sm" style="min-height: 450px;">
          <div class="card-body">
            <h5 class="card-title fn-weight-500"> {{ $t('event.customer_service_message') }} </h5>
            <MessageCard 
              alertType="indicator-info"
              :alertTitle="$t('claims.new.comments')" 
              :alertText="$t('event.csm_info')"
            />
            <MessageCard 
              alertType="indicator-danger"
              :alertTitle="$t('claims.new.very_important')" 
              :alertText="$t('event.csm_danger')"
            />

            <p v-if="vehicle.notes.length < 1" style="font-weight: 100; font-size: 15px;"> {{$t('event.no_csm')}} </p>
              <div v-else class="msg-wrapper m-b-xs">
                <span v-show="csm === ''"> {{$t('event.no_csm')}} </span>
                <span> {{ csm }} </span>
              </div> 

          </div>
          <div class="notes-footer flex-center">
            <div class="flex-center" style="width:100%; justify-content: space-between;">
              <input type="text" v-model="newCSM" placeholder="Write your message here ..." class="col-md-9" id="newNote" @keydown.enter="updateCsm">
              <div class="col-md-3 flex-center"> 
                <button v-if="!updatingCsm" class="event-btn bg-primary flex-center" @click="updateCsm"> 
                  <div v-if="updatingCsm" class="spinner-border" role="status" style="margin-right: 5px">
                      <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                  </div>
                  <i class="material-icons" :class="updatingCsm ? 'display-none' : '' "> send </i> 
                  <span class="m-l-xxs"> {{$t('event.update')}} </span> 
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import EventImage from "../../components/EventImage.vue"
import EventService from "../../services/event.service";
import VehicleService from "../../services/vehicle.service";
import VehicleNotes from "../vehicle/VehicleNotes.vue";
import Timestamp from '../../components/Timestamp.vue';
import Swal from 'sweetalert2';
import { DateTime } from "luxon";
import MessageCard from "../../components/MessageCard.vue";

export default {
  name: "Event",
  components: {
    EventImage,
    VehicleNotes,
    Timestamp,
    MessageCard
},
  props: {
    id: Number
  },
  data(){
    return {
      event: null,
      csm: "",
      updatingCsm: false,
      updatingBlacklist: false,
      vehicle: null,
      newNote: "",
      addingNewNote: false,
      newCSM: "",
      localBlacklist: {},
      nationalBlacklist: {}
      // plateHighlight: {
      //   x: 0,
      //   y: 0,
      //   width: 0,
      //   height: 0,
      //   magnify: 3,
      //   canvas: null
      // }
    }
  },
  mounted(){
    EventService.getEvent(this.$store.state.auth.user.selectedClient, this.id)
    .then(response => {
      this.event = response.data;
      if(!this.event.vehicle.customerServiceMessage){
          this.event.vehicle.customerServiceMessage = {
              message: ""
          }
      }
      this.csm = this.event.vehicle.customerServiceMessage.message;
      this.vehicle = response.data.vehicle;
      this.localBlacklist = response.data.blacklist.local;
      this.nationalBlacklist = response.data.blacklist.national;
    })
    .catch(error => {
      console.error(error);
    })
  },
  methods: {
    updateCsm(){
      this.updatingCsm = true;
      EventService.updateCustomerServiceMessage(this.$store.state.auth.user.selectedClient, this.event.id, this.newCSM)
      .then(() => {
        this.csm = this.newCSM;
        this.newCSM = "";
        // this.csm = response.data;
        this.$success("Updated Customer Service Message");
      })
      .catch(error => {
        this.$error('Failed to update Customer Service Message', error);
      })
      .finally(() => {
        this.updatingCsm = false;
      });
    },
    async blacklist(){
      if(this.updatingBlacklist) return;
      this.updatingBlacklist = true;
      const {value: reason} = await Swal.fire({
            title: `Select a reason for blacklisting ${this.event.vehicle.plate}`,
            input: 'select',
            inputOptions: {
                "Drive Off": this.$t('event.drive_off'),
                "No Means of Payment": this.$t('event.no_means_of_payment'),
                "Fly Tipping": this.$t('event.fly_tipping'),
                "Theft": this.$t('event.theft'),
                "Aggressive": this.$t('event.aggressive'),
                "Plate Mismatch": this.$t('event.plate_mismatch')
            },
            inputPlaceholder: 'Select a reason',
            showCancelButton: true
        });

        if(reason){
            EventService.blacklist(this.event.site.id, this.event.id, reason)
            .then(() => {
              this.$success("Updated blacklist status");
              this.event.blacklist.local.reason = reason;
              this.event.blacklist.local.blacklisted_at = DateTime.local().toSeconds();
              this.event.blacklist.national.by.push({
                name: this.selectedClient.name,
                reason: reason,
                blacklisted_at: DateTime.local().toSeconds()
              })
            })
            .catch(error => {
              this.$error("Failed to update blacklist status", error);
            })
            .finally(() => {
              this.updatingBlacklist = false;
            })
        }else{
          this.updatingBlacklist = false;
          this.$warning("You must provide a reason to blacklist!");
        }
    },
    unblacklist(){
      if(this.updatingBlacklist) return;
      this.updatingBlacklist = true;
      EventService.unblacklist(this.event.site.id, this.event.id)
      .then(() => {
        this.$success("Updated blacklist status");
        this.event.blacklist.local.reason = null;
        this.event.blacklist.national.by = this.event.blacklist.national.by.filter((entry) => {
          return entry.name !== this.selectedClient.name;
        });
      })
      .catch(error => {
        this.$error("Failed to update blacklist status", error);
      })
      .finally(() => {
        this.updatingBlacklist = false;
      })
    },
    createNote() {
      this.addingNewNote = true;
      VehicleService.putVehicleNote(this.$store.state.auth.user.selectedClient, this.vehicle.id, this.newNote)
      .then(response => {
        this.vehicle.notes = response.data.note;
        this.newNote = "";
        this.addingNewNote = false;
      })
      .catch(error => {
        this.error(this.$t('vehicle_notes.error_msg'), error);
        this.addingNewNote = false;
      });
    },
    // showHighlight(){
    //   this.plateHighlight.canvas = document.getElementById('plateCanvas');
    //   if(this.event.coordinates === undefined){
    //     console.log("No coordinates");
    //     return;
    //   }
    //   let coordinates = this.event.coordinates.replace(/\[/g, '').replace(/\]/g, '');
    //   coordinates = coordinates.split(",");
    //   this.plateHighlight.x = coordinates[0] -20;
    //   if(this.plateHighlight.x < 0) this.plateHighlight.x = 0;
    //   this.plateHighlight.y = coordinates[1] -20;
    //   if(this.plateHighlight.y < 0) this.plateHighlight.y = 0;
    //   this.plateHighlight.width = Math.abs(coordinates[0] - coordinates[2]) + 20;
    //   this.plateHighlight.height = Math.abs(coordinates[1] - coordinates[3]) + 20;
    //   console.log(coordinates);
    //   const image = document.getElementById('event_image');
    //   const ctx = this.plateHighlight.canvas.getContext('2d');

    //   ctx.drawImage(image, this.plateHighlight.x, this.plateHighlight.y, this.plateHighlight.width, this.plateHighlight.height, 0, 0, this.plateHighlight.width * this.plateHighlight.magnify, this.plateHighlight.height * this.plateHighlight.magnify);
    // },
    // magnify(imgID, zoom) {
    //         this.showHighlight();
    //         //https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp
    //         var img, glass, w, h, bw;
    //         img = document.getElementById(imgID);
    //         /*create magnifier glass:*/
    //         glass = document.createElement("DIV");
    //         glass.setAttribute("class", "img-magnifier-glass");
    //         /*insert magnifier glass:*/
    //         img.parentElement.insertBefore(glass, img);
    //         /*set background properties for the magnifier glass:*/
    //         glass.style.backgroundImage = "url('" + img.src + "')";
    //         glass.style.backgroundRepeat = "no-repeat";
    //         glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
    //         bw = 3;
    //         w = glass.offsetWidth / 2;
    //         h = glass.offsetHeight / 2;
    //         /*execute a function when someone moves the magnifier glass over the image:*/
    //         glass.addEventListener("mousemove", moveMagnifier);
    //         img.addEventListener("mousemove", moveMagnifier);
    //         /*and also for touch screens:*/
    //         glass.addEventListener("touchmove", moveMagnifier);
    //         img.addEventListener("touchmove", moveMagnifier);
    //         function moveMagnifier(e) {
    //             var pos, x, y;
    //             /*prevent any other actions that may occur when moving over the image*/
    //             e.preventDefault();
    //             /*get the cursor's x and y positions:*/
    //             pos = getCursorPos(e);
    //             x = pos.x;
    //             y = pos.y;
    //             glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
    //             /*prevent the magnifier glass from being positioned outside the image:*/
    //             if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
    //             if (x < w / zoom) {x = w / zoom;}
    //             if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
    //             if (y < h / zoom) {y = h / zoom;}
    //             /*set the position of the magnifier glass:*/
    //             glass.style.left = (x - w) + "px";
    //             glass.style.top = (y - h) + "px";
    //             /*display what the magnifier glass "sees":*/
    //             glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
    //         }
    //         function getCursorPos(e) {
    //             var a, x = 0, y = 0;
    //             e = e || window.event;
    //             /*get the x and y positions of the image:*/
    //             a = img.getBoundingClientRect();
    //             /*calculate the cursor's x and y coordinates, relative to the image:*/
    //             x = e.pageX - a.left;
    //             y = e.pageY - a.top;
    //             /*consider any page scrolling:*/
    //             x = x - window.pageXOffset;
    //             y = y - window.pageYOffset;
    //             return {x : x, y : y};
    //         }
    //     }
  },
  computed: {
    selectedClient: function(){
      return this.$store.state.auth.user.clients.filter((client) => {
        return parseInt(client.id) === parseInt(this.$store.state.auth.user.selectedClient);
      })[0]
    }
  }
}
</script>

<style scoped>

.btn {
  /* border: 1px solid transparent; */
  border: none;
}

.flex {
  display: flex;
  justify-content: space-between;
}

textarea {
  border: none;
  resize: none;
}

.left, .right {
  display: flex;
  flex-direction: column;
}

.left span, .right span {
  padding: 15px 0;
  font-weight: 300;
}

.btn.float-end {
  border: 1px solid transparent;
}

.btn.float-end:hover {
  border: 1px solid white;
}

.image-footer {
  padding: 10px !important;
}

.image-footer p{
  font-size: 20px;
  font-weight: 100;
  margin: 0;
}

.notes-footer {
  height: 70px;
  border-radius: 0 0 10px 10px;
  background-color: #2E2E3F;
}

.msg-wrapper {
  width: fit-content;
  padding: 10px 25px;
  background-color: #2E2E3F;
  border-radius: 10px;
  overflow: hidden;
}

.note-timestamp {
  font-size: 11px;
  color: var(--font-color-secondary);
}

.msg-wrapper > span {
  font-weight: 300;
}

.no-notes {
  font-weight: 100;
  font-style: italic;
  color: var(--font-color-secondary);
}

#newNote {
  background-color: #2E2E3F;
  border: none;
  padding: 0 20px;
  color: white;
}

#newNote:focus {
  outline: none;
}

.event-btn {
  border: none;
  padding: 10px 15px;
  color: white;
  border-radius: 5px;
}

.event-btn i {
  color: white;
  transform: rotate(-45deg);
}

.grid {
  display: grid;
  /* grid-template-columns: 33% 33% 33%; */
  grid-template-columns: 50% 50%;
}

.grid div {
    margin-bottom: 10px;
}

#card-content {
  overflow-y: scroll;
  max-height: 300px;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1f1f2b; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media (max-width: 768px) {
  .col-md-8 {
    width: 100%;
  }

}


</style>